html {
  scroll-behavior: smooth;
}

body {
  margin: auto 0;
  background-color: #f0f0f0;
  color: rgb(83, 83, 83);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: 0.5s;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
}

#svg-logo {
  height: 80%;
}

x-highlight {
  font-weight: bold;
  color: #569dc9;
}

.welcome-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.welcome-message h2 {
  text-align: center;
  font-size: 3rem;
}

.social-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100px;
}

.inner-social {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 30%;
  width: 100%;
  height: 100%;
}

.social-item {
  align-self: center;
  justify-self: center;
  width: 100%;
  transition: 0.5s;
}

.social-item:hover {
  transform: rotate(20deg);
  transition: 0.5s;
}

.section {
  padding: 0 8vw;
}

.section-title {
  font-size: 2rem;
  color: #457b9d;
  text-align: center;
  text-decoration: underline;
}

.grid-section {
  display: grid;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.54);
}

.about-section {
  grid-template-columns: 40% auto;
  column-gap: 30px;
  padding: 20px;
}

.skill-section {
  grid-template-columns: 49% 49%;
  column-gap: 2%;
  row-gap: 10px;
}

.grid-skill {
  display: grid;
  margin: 10px;
  padding: 10px;
  border: solid 3px rgba(173, 173, 173, 0.308);
  border-radius: 15px;
  grid-template-columns: 20% auto;
  grid-template-rows: auto;
  column-gap: 20px;
  box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.11);
}

.skill-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Make all svg same size */
.skill-logo > div {
  width: 100%;
  height: 100%;
}

.skill-title {
  text-align: center;
  font-weight: bold;
  color: rgb(155, 151, 151);
}

.work-section {
  grid-template-columns: 49% 49%;
  column-gap: 2%;
  row-gap: 10px;
}

.grid-work {
  display: grid;
  margin: 10px;
  padding: 15px;
  border: solid 3px rgba(173, 173, 173, 0.308);
  border-radius: 15px;
  grid-template-rows: 250px auto;
  row-gap: 15px;
  box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 2px 0px 32px -1px rgba(0, 0, 0, 0.11);
}

.grid-work img {
  transition: 0.5s;
  border: 1px solid rgb(172, 172, 235);
}

.grid-work img:hover {
  cursor: pointer;
  transform: scale(0.9, 0.9);
  transition: 0.5s;
}

.grid-work-detail {
  display: grid;
  grid-template-rows: 25px 25px auto;
}

.work-title {
  font-weight: bold;
  color: #457b9d;
}

.work-tech {
  font-weight: bold;
  color: rgb(156, 155, 155);
}

.work-desc {
  font-style: italic;
  opacity: 0.8;
}

.footer {
  display: flex;
  justify-content: center;
  height: 150px;
  background-color: #457b9d;
  margin-top: 50px;
  text-align: center;
  color: white;
}

.navbar-div {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
}

.navbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 90%;
  height: 100%;
}

.navbar a {
  text-decoration: none;
  color: whitesmoke;
}

.inner-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-nav:hover {
  transform: translate(0, 5px);
}

.inner-nav:nth-child(odd) {
  background-color: #212529;
}

.inner-nav:nth-child(even) {
  background-color: #495057;
}

.inner-nav:nth-child(1) {
  border-top-left-radius: 15px;
}

.inner-nav:nth-last-child(1) {
  border-top-right-radius: 15px;
}

/* For small device */
@media (max-width: 900px) {
  .social-div {
    width: 30%;
  }

  .section {
    padding: 0 4vw;
  }

  .grid-section {
    grid-template-columns: auto;
  }

  .grid-work {
    grid-template-rows: 200px auto;
  }

  .work-section {
    grid-template-columns: auto;
  }
}

@media (max-width: 500px) {
  .welcome-message h2 {
    text-align: center;
    font-size: 2.5rem;
  }

  .social-div {
    width: 50%;
  }

  .grid-skill {
    grid-template-columns: auto;
    row-gap: 10px;
  }

  /* Make all svg same size */
  .skill-logo > div {
    width: 25%;
    height: 100%;
  }

  .grid-work-detail {
    grid-template-rows: 25px auto auto;
  }

  .navbar {
    width: 95%;
  }
}
